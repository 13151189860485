export default async function (context: any): Promise<void> {
	// Only request if the store is not populated by basedata
	const domain = context.$getDomain();
	const domainChanged = context.store.state.MISC_DATA.current_domain !== domain;
	const empty = context.store.state.MISC_DATA.countries.length < 1;

	const base_url = {
		at: context.$config.url_at,
		de: context.$config.url_de,
		cz: context.$config.url_cz,
		hu: context.$config.url_hu,
		sk: context.$config.url_sk,
	};

	context.store.commit('storeBaseUrl', base_url);

	let api_base_url = context.$config.apiBaseUrl as string;
	if (api_base_url) {
		api_base_url = api_base_url.replace('.at', `.${domain}`);
		context.$axios.defaults.baseURL = api_base_url;
	}

	if (empty || domainChanged) {
		context.$logger.console({ message: `fetch base data from middleware for domain '${domain}'` });
		await context.store.dispatch('GET_BASE_DATA', domain);
	}

	if (!process.client) {
    const memoryUsage = process.memoryUsage();
    const usedPercentage = (memoryUsage.heapUsed / memoryUsage.heapTotal) * 100;
    console.log('After fetchMiscData middleware heapTotal: ' + memoryUsage.heapTotal + ' heapUsed: ' + memoryUsage.heapUsed + ' -> ' + usedPercentage.toFixed(2) + '%')
	}
}
