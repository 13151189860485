/* eslint-disable prefer-regex-literals */
import Vue from 'vue';
import dayjs from 'dayjs';

import MISC_DATA from '@/store/modules/MiscDataModule';
import UTILS from '@/store/modules/UtilityModule';
import { routesNames } from '~/router/route_helper';

require('dayjs/locale/en');
require('dayjs/locale/de');
require('dayjs/locale/cs');
require('dayjs/locale/hu');
require('dayjs/locale/sk');

const localeHandler = Vue.extend({
  computed: {
    MISC_DATA: () => MISC_DATA,
    UTILS: () => UTILS,

    currentLocale(): string {
      return this.$i18n.locale;
    },

    localeToBCP47(): string {
      return this.$i18n.locale.split('-')[0];
    },

    countries(): typeof MISC_DATA.current_locales {
      return MISC_DATA.current_locales;
    },

    getStartingLocale(): string {
      // Check stored language first
      if (process.client) {
        const stored_lang = sessionStorage.getItem('lang');
        if (stored_lang && stored_lang.length === 5) {
          const l = stored_lang.split('-')[0];
          const bu = this.$getDomain();
          return [l, bu.toUpperCase()].join('-');
        }
      }

      // Fall back to default only if no stored language
      const default_lang = {
        at: 'de',
        de: 'de',
        cz: 'cs',
        hu: 'hu',
        sk: 'sk',
      } as any;

      const bu = this.$getDomain();
      return [default_lang[bu], bu.toUpperCase()].join('-');
    },
  },

  async created() {
    UTILS.setPageLoader(true);

    if (this.$route.name === routesNames.login) {
      if (this.$route.query.hasOwnProperty('redirect')) {
        if (process.client) {
          const isReloadCompleted = sessionStorage.getItem('reload');
          if (isReloadCompleted !== 'completed') {
            sessionStorage.setItem('reload', 'completed');
            window.location.reload();
          }
        }
      }
    }
    // StartingLocale is either the one stored in session or the logical one using domain.
    const locale = this.getStartingLocale;

    const default_per_domain = {
      at: 'de-AT',
      de: 'de-AT',
      cz: 'cs-CZ',
      hu: 'hu-HU',
      sk: 'sk-SK',
    } as any;

    const default_locale = default_per_domain[this.$getDomain()];

    // console.log('Trans requested server side ->', this.UTILS.loaded_translation);
    // console.log('Will be assigned to key:', default_locale);

    // First set the fallback that was request server-side
    this.$i18n.setLocaleMessage(default_locale, this.UTILS.loaded_translation);

    // if (!this.UTILS.loaded_translation) {
    // 	await this.loadLocaleMessagesAsync(default_locale);
    // }

    await this.loadLocaleMessagesAsync(locale);
    dayjs.locale(this.localeToBCP47);

    UTILS.setPageLoader(false);
  },

  methods: {
    setI18nLanguage(lang: string): string {
      this.$i18n.locale = lang;
      this.$axios.defaults.headers.common['Accept-Language'] = lang;

      let el;
      if (process.client && document) {
        el = document.querySelector<HTMLElement>('html'); // typecasting to prevent stupid error 'Object is possibly null'
        if (!el) {
          throw new ReferenceError('Document selector not found.');
        }
        el.setAttribute('lang', lang);
      }

      dayjs.locale(this.localeToBCP47);

      let main_loc = '';
      switch (lang.split('-')[0]) {
        case 'cs':
          main_loc = 'cs-CZ';
          break;
        case 'hu':
          main_loc = 'hu-HU';
          break;
        case 'sk':
          main_loc = 'sk-SK';
          break;
        case 'en':
          main_loc = 'en-AT';
          break;
        case 'de':
          main_loc = 'de-AT';
      }
      const messages = this.$i18n.messages[main_loc] as any;
      const unfocused_title =
        messages && messages.general && messages.general.unfocused_tab
          ? messages.general.unfocused_tab
          : '';
      this.UTILS.storeDocumentUnfocusTitle(unfocused_title);

      const new_meta = this.$i18n.t('meta');
      if (new_meta) {
        this.MISC_DATA.GENERATE_META_DATA(new_meta);
      }

      return lang;
    },

    isLocaleAlreadyLoaded(locale: string): boolean {
      const loaded_trans_keys = Object.keys(this.$i18n.messages);
      // Have to check for the fallback cause we don't handle override of EN and CZ for other domains (for now)
      return (
        loaded_trans_keys.includes(locale) && Object.keys(this.$i18n.messages[locale]).length > 0
      );
    },

    async loadLocaleMessagesAsync(specific_loc?: string): Promise<any> {
      const locale = specific_loc ?? this.currentLocale;

      if (this.isLocaleAlreadyLoaded(locale)) {
        this.setI18nLanguage(locale);
        return Promise.resolve(locale);
      }

      // First and if needed, request the fallback for this locale-BU (ex. de-DE will request de-AT)
      const fallbacks = (this.$i18n.fallbackLocale as any)[locale];
      // console.log(`fallbacks for ${locale}`, fallbacks)
      if (fallbacks !== undefined) {
        for (const i in fallbacks) {
          const fallback_locale = fallbacks[i];
          // console.log(this.$i18n.messages)
          // if was not already loaded
          if (!this.isLocaleAlreadyLoaded(fallback_locale)) {
            const [lang, bu] = fallback_locale.split('-');
            const res = await this.UTILS.GET_TRANSLATION({ lang: lang, bu: bu.toLowerCase() });

            if (res) {
              this.$i18n.setLocaleMessage(fallback_locale, res as any);
            }
          }
        }
      }

      // Then request the actual locale
      const res = await this.UTILS.GET_TRANSLATION({
        lang: locale.split('-')[0],
        bu: this.$getDomain(),
      });

      if (res) {
        this.$i18n.setLocaleMessage(locale, res as any);
      }

      this.setI18nLanguage(locale);

      return Promise.resolve(locale);
    },
  },
});

export default localeHandler;
