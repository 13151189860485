/* eslint-disable prefer-regex-literals */
import Vue from 'vue';

const currentDomain = Vue.extend({
  computed: {
    isCzDomain(): boolean {
      return this.$getDomain() === 'cz';
    },
    isHuDomain(): boolean {
      return this.$getDomain() === 'hu';
    },
    isAtDomain(): boolean {
      return this.$getDomain() === 'at';
    },
    isDeDomain(): boolean {
      return this.$getDomain() === 'de';
    },
    isSkDomain(): boolean {
      return this.$getDomain() === 'sk';
    },
    currentTLD(): string {
      return this.$getDomain();
    },
  },
});

export default currentDomain;
