import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

export default ({ app }) => {
	Vue.use(VueGoogleMaps, {
		load: {
			// key: app.$config.googleApiKey,
			key: 'AIzaSyB5n7ys8MDQx1-Wj5AsDwASFVMii4oZt0Q',
			libraries: 'places'
		},
		installComponents: true
	});
	Vue.component('GmapCluster', GmapCluster);
};
